.div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.div-row-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.div-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.div-col-margin-20 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 20px;
}

.alani-header {
  font-size: 3em;
  font-weight: 600;
  color: orange;
  text-align: center;
  margin: 0;
  font-family: 'Tw Cen MT Std', sans-serif;
}

.toast {
  left: 50%;
  position: fixed;
  transform: translate(-50%, 0px);
  z-index: 9999;
}

.alert-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 360px;
  margin-top: 20px;
  text-align: center;
}

html {
  font-size: 17px;
}